require('dotenv').config();
const alchemyKey = "wss://eth-ropsten.alchemyapi.io/v2/0cMhqWJ6XRsAlJqufOZom01FynP251u9";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 
var CryptoJS = require("crypto-js");

const contractABI = require("../contract-abi.json");
const contractAddress = "0xe40D90b8b28E07182DF3733Fe2BB34fB5a4625aa";

const passphrase = `smartContract`; // what the private key is encrypted with

export const helloWorldContract = new web3.eth.Contract(
    contractABI,
    contractAddress
  );

//export const helloWorldContract;

export const getAnzahlungsAmount = async () => {
  const anzahlungAmount = await helloWorldContract.methods.anzahlungAmount().call();

  return anzahlungAmount;
} 

export const getContractAmount = async () => {
  const contractAmount = await helloWorldContract.methods.contractAmount().call();

  return contractAmount;
} 

export const getDeliveryTerns = async () => {
  const deliveryTerms = await helloWorldContract.methods.deliveryTerms().call();
  

  return deliveryTerms;
} 

export const getStartHarbor = async () => {
  const startHarbor = await helloWorldContract.methods.startHarbor().call();

  return startHarbor;
} 

export const getStartHarborDecrypted = async () => {
  let startHarborcurrent = await helloWorldContract.methods.startHarbor().call();
  
  return CryptoJS.AES.decrypt(startHarborcurrent, passphrase).toString(CryptoJS.enc.Utf8);
} 

export const getDeliveryTermsDecrypted = async () => {
  let deliveryTermscurrent = await helloWorldContract.methods.deliveryTerms().call();
  
  return deliveryTermscurrent;
} 

export const getEndHarborDecrypted = async () => {
  let startHarborcurrent = await helloWorldContract.methods.endHarbor().call();
  
  return CryptoJS.AES.decrypt(startHarborcurrent, passphrase).toString(CryptoJS.enc.Utf8);
} 

export const getEndHarbor = async () => {
  const endHarbor = await helloWorldContract.methods.endHarbor().call();

  return endHarbor;
} 

export const getStatus = async () => { 
    const message = await helloWorldContract.methods.status().call(); 

    let returnMessage = "unbekannter Status";
    if (message == 1){
      returnMessage = "Die Vertragsverhandlungen sind abgeschlossen und der Smartcontract wurde aufgesetzt. Das Aufsetzen kann von allen Beteiligten erfolgen.";
    }
    else if(message == 2){
      returnMessage = "Die Anzahlung wurde vom Importeur geleistet.";
    }
    else if(message == 4){
      returnMessage = "Das Akkreditiv wurde wurde von der Importeurbank überprüft und dem Hotscan unterzogen.";
    }
    else if(message == 5){
      returnMessage = "Das Akkreditiv wurde von der Exporteurbank und dem Hotscan überprüft.";
    }
    else if(message == 6){
      returnMessage = "Die Produktion der Waren ist abgeschlossen.";
    }
    else if(message == 7){
      returnMessage = "Der Stauraum bei der Reederei wurde von dem Importeur gebucht.";
    }
    else if(message == 8){
      returnMessage = "Die Waren wurden an den Transportdienstleister der die Ware zum Hafen fährt übergeben.";
    }
    else if(message == 9){
      returnMessage = "Die Waren wurden vom Zoll überprüft.";
    }
    else if(message == 10){
      returnMessage = "Das Bill of Lading wurde von der Reederei ausgestellt.";
    }
    else if(message == 13){
      returnMessage = "Das Bill of Lading wurde von der Exportbank überprüft, die Zahlung an den Exporteur wurde ausgelöst, das BoL ist an die Importeurbank geschickt worden.";
    }
    else if(message == 15){
      returnMessage = "Das Bill of Lading wurde von der Importbank überprüft, die BoL Dokumente wurden an den Importeur weiter geleitet, Der Akkreditiv Prozess ist hier abgeschlossen.";
    }
    return {0:returnMessage, 1:message};
};

export const getParties = async () => { 
    let importeur = await helloWorldContract.methods.importeur().call(); 
    let exporteur = await helloWorldContract.methods.exporteur().call(); 
    let importeurBank = await helloWorldContract.methods.importeurBank().call(); 
    let exporteurBank = await helloWorldContract.methods.exporteurBank().call(); 
    let shippingCompany = await helloWorldContract.methods.shippingCompany().call(); 

    return {1: importeur , 2: exporteur, 3: importeurBank, 4: exporteurBank, 5:shippingCompany}; 
};

export const connectWallet = async (status) => {  
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let data = await getParties();
        let currentAdress = addressArray[0];
        let name = "Unbekannt";
        let type = 0;

        console.log(status);

        if(status){
          if(status == 1){
            name = "Gazprom";
            type = 5;
          }
          else if(status == 2){
            name = "SberBank";
            type = 5;
          }
          else if(status == 3){
            name = "SberBank";
            type = 5;
          }
          else if(status == 4){
            name = "Deutsche Bank";
            type = 5;
          }
          else if(status == 5){
            name = "Deutscher Hersteller";
            type = 5;
          }
          else if(status == 6){
            name = "Gazprom";
            type = 5;
          }
          else if(status == 7){
            name = "Exporteur";
            type = 5;
          }
          else if(status == 8){
            name = "Reederei";
            type = 5;
          }
          else if(status == 9){
            name = "Reederei";
            type = 5;
          }
          else if(status == 10){
            name = "Deutsche Bank";
            type = 5;
          }
          else if(status == 11){
            name = "Deutsche Bank";
            type = 5;
          }
          else if(status == 13){
            name = "SberBank";
            type = 5;
          }
          else if(status == 15){
            name = "Gazprom";
            type = 5;
          }
        }

     
        const obj = {
          name: name,
          type: type
        };

        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
          name: "",
          type: 0
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
        name: "",
        type: 0
      };
    }
};

export const getForm = async (type, status) => {
  if(status){
    if(status == 1){
      return ["Anzahlung leisten." , 1, 1];
    }
    else if(status == 2){
      return ["Akkreditiv überprüft & Hotscan durchgeführt." , 1, 2];
    }
    else if(status == 3){
      return ["Akkreditiv.", 1, 3];
    }
    else if(status == 4){
      return ["Akkreditiv überprüft & Hotscan durchgeführt.", 1, 4];
    }
    else if(status == 5){
      return ["Produktion abgeschlossen.", 1, 5];
    }
    else if(status == 6){
      return ["Stauraum gebucht.", 1, 6];
    }
    else if(status == 7){
      return ["Waren übergeben.", 1, 7];
    }
    else if(status == 8){
      return ["Zollprüfung erfolgeich.", 1, 8];
    }
    else if(status == 9){
      return ["Bill Of Lading erstellen.", 1, 9];
    }
    else if(status == 10){
      return ["Bill of Lading überprüft.", 1, 10];
    }
    else if(status == 11){
      return ["Akkreditiv decken und den deutschen Herrsteller auszahlen.", 1, 11];
    }
    else if(status == 13){
      return ["Akreditiv Dokumente überprüft und für Abholung freischalten.", 1, 12];
    }
    else if(status == 15){
      return ["Das Akkreditiv ist abgeschlossen, der Importeur kann unter Vorlage des Smart Contract die Waren abholen.", 0];
    }
    else{
      return ["Dein Account kann aktuell nicht mit dem Vertrag interagieren, da auf andere Partein gewartet wird.", 0];
    }
  }
}

export const sendAnzahlung = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  let sendAmount = await helloWorldContract.methods.anzahlungAmount().call(); 

  const amount = web3.utils.toWei(sendAmount, 'gwei');
  const value = web3.utils.toHex(amount);

  window.ethereum
  .request({
    method: 'eth_sendTransaction',
    params: [
      {
        from: addressArray[0],
        to: contractAddress,
        value: value
      },
    ],
  })
  .then((txHash) => console.log(txHash))
  .catch((error) => console.error);
}

export const sendContractAmmount = async () => {
  const addressContractArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  
  let sendContractAmount = await helloWorldContract.methods.contractAmount().call() - await helloWorldContract.methods.getBalance().call(); 
  sendContractAmount = sendContractAmount + "";
  let contractAmount = web3.utils.toWei(sendContractAmount, 'gwei');
  const contractValue = web3.utils.toHex(contractAmount);

  window.ethereum
  .request({
    method: 'eth_sendTransaction',
    params: [
      {
        from: addressContractArray[0],
        to: contractAddress,
        value: contractValue
      },
    ],
  })
  .then((txHash) => console.log(txHash))
  .catch((error) => console.error);
}


export const checkAkkredetivOpen = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const checkAkkredetivOpentransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.checkAkkredetivOpen().encodeABI(),
  };
  sendTransaction(checkAkkredetivOpentransactionParameters);
}
export const checkHotscanOpen = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const checkHotscanOpenTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.checkHotscanOpen().encodeABI(),
  };
  sendTransaction(checkHotscanOpenTransactionParameters);
}

export const checkHotscanExporteur = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const checkHotscanExporteurTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.checkHotscanExporteur().encodeABI(),
  };
  sendTransaction(checkHotscanExporteurTransactionParameters);
}

export const productionDone = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const productionDoneTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.productionDone().encodeABI(),
  };
  sendTransaction(productionDoneTransactionParameters);
}

export const bookedStorage = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const bookedStorageTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.bookedStorage().encodeABI(),
  };
  sendTransaction(bookedStorageTransactionParameters);
}

export const goodsHandedOver = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const goodsHandedOverTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.goodsHandedOver().encodeABI(),
  };
  sendTransaction(goodsHandedOverTransactionParameters);
}

export const dutyReceived = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const dutyReceivedTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.dutyReceived().encodeABI(),
  };
  sendTransaction(dutyReceivedTransactionParameters);
}

export const createBillOfLading = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const createBillOfLadingTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.createBillOfLading().encodeABI(),
  };
  sendTransaction(createBillOfLadingTransactionParameters);
}

export const checkBillOfLadingExportBank = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const checkBillOfLadingExportBankTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.checkBillOfLadingExportBank().encodeABI(),
  };
  sendTransaction(checkBillOfLadingExportBankTransactionParameters);
}

export const checkBillOfLadingImportBank = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const checkBillOfLadingImportBankTransactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.checkBillOfLadingImportBank().encodeABI(),
  };
  sendTransaction(checkBillOfLadingImportBankTransactionParameters);
}

export const resetContract = async () => {
  const addressArray = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  //Verschlüsselung der Texte mit einem Passwort
  var starthaborcurrentEncrypted = CryptoJS.AES.encrypt("Hamburg", passphrase).toString();
  var endhaborcurrentEncrypted = CryptoJS.AES.encrypt("Helsinki", passphrase).toString();

  const resetContractTransaction = {
    to: contractAddress, // Required except during contract publications.
    from: addressArray[0], // must match user's active address.
    data: helloWorldContract.methods.resetContract(20000000, 100000000, addressArray[0], addressArray[0], addressArray[0], addressArray[0], addressArray[0], starthaborcurrentEncrypted, endhaborcurrentEncrypted).encodeABI(),
  };
  sendTransaction(resetContractTransaction);
}

const sendTransaction = async (parameters) => {
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [parameters],
    });
    return {
      status: "",
    };
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
}

export const getCurrentWalletConnected = async () => {
  
};