import React from "react";
import { useEffect, useState } from "react";
import {
  helloWorldContract,
  connectWallet,
  resetContract,
  updateMessage,
  getStatus,
  getForm,
  sendAnzahlung,
  checkAkkredetivOpen,
  checkHotscanOpen,
  checkHotscanExporteur,
  productionDone,
  bookedStorage,
  goodsHandedOver,
  dutyReceived,
  createBillOfLading,
  checkBillOfLadingExportBank,
  sendContractAmmount,
  checkBillOfLadingImportBank,
  getCurrentWalletConnected,
  getDeliveryTermsDecrypted,
  getEndHarborDecrypted,
  getStartHarborDecrypted,
  getAnzahlungsAmount,
  getContractAmount,
  getDeliveryTerns,
  getStartHarbor,
  getEndHarbor
} from "./util/interact.js";

import alchemylogo from "./alchemylogo.svg";

let functionArray = [sendAnzahlung, checkAkkredetivOpen, checkHotscanOpen, checkHotscanExporteur, productionDone, bookedStorage, goodsHandedOver, dutyReceived, createBillOfLading, checkBillOfLadingExportBank, sendContractAmmount, checkBillOfLadingImportBank];

const HelloWorld = () => {
  //state variables
  const [walletName, setWallet] = useState("");
  const [walletType,  setType] = useState("");
  const [status, setStatus] = useState("");
  const [form, setForm] = useState("");
  const [button, setButton] = useState("");
  const [buttonFunction, setbuttonFunction ] = useState("");
  const [message, setMessage] = useState("No connection to the network."); //default message
  const [anzahlungsAmount, setAnzahlungsAmount] = useState(""); //default message
  const [contractAmount, setContractAmount] = useState(""); //default message
  const [deliveryTerns, setDeliveryTerns] = useState(""); //default message
  const [startHarbor, setStartHarbor] = useState(""); //default message
  const [endHarbor, setEndHarbor] = useState(""); //default message
  const [endHarborDecrypted, setEndHarborDecrypted] = useState(""); //default message
  const [startHarborDecrypted, setStartHarborDecrypted] = useState(""); //default message
  const [deliveryTermsDecrypted, setDeliveryTermsDecrypted] = useState(""); //default message

  //called only once
  useEffect(() => {
    async function fetchMessage() {
      const message = await getStatus();
      const anzahlungsAmount = await getAnzahlungsAmount();
      const contractAmount = await getContractAmount();
      const deliveryTerns = await getDeliveryTerns();
      const startHarbor = await getStartHarbor();
      const endHarbor = await getEndHarbor();
      const endHarborDecrypted = await getEndHarborDecrypted();
      const startHarborDecrypted = await getStartHarborDecrypted();
      const deliveryTernsDecrypted = await getDeliveryTermsDecrypted();
      setMessage(message[0]);
      setStatus(message[1]);
      setAnzahlungsAmount(anzahlungsAmount);
      setContractAmount(contractAmount);
      setDeliveryTerns(deliveryTerns);
      setStartHarbor(startHarbor);
      setEndHarbor(endHarbor);
      setEndHarborDecrypted(endHarborDecrypted);
      setStartHarborDecrypted(startHarborDecrypted);
      setDeliveryTermsDecrypted(deliveryTernsDecrypted);
      addSmartContractListener();
      const walletResponse = await connectWallet(message[1]);
      setWallet(walletResponse.name);
      setType(walletResponse.type);
      actualForm();
    }
    fetchMessage();
  }, []);

  function addSmartContractListener() { //TODO: implement
    helloWorldContract.events.UpdatedStatus({},  async (error, data) => {
      const message = await getStatus();
      setMessage(message[0]);
      setStatus(message[1]);
      const walletResponse = await connectWallet(message[1]);
      setWallet(walletResponse.name);
      setType(walletResponse.type);
      actualForm();
    });
  }

  function addWalletListener() { //TODO: implement
    
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet(status);
    setWallet(walletResponse.name);
    setType(walletResponse.type);
    actualForm();
  };

  const actualForm = async () => {
    const form = await getForm(walletType, status);
    if(form){
      setForm(form[0]);
      setButton(form[1]);
      setbuttonFunction(form[2]-1);
    }
  }

  actualForm();

  const onUpdatePressed = async () => { //TODO: implement
    
  };


  //the UI
  return (    
    <div id="container" style={{ marginTop: "10px" }}>
      <p style={{"fontSize":"30px", "display": "inline-block", "margin": "auto"}}>TF - Akkredetiv - Zu Demo Zwecken, ist es möglich alle Transaktionen mit einer Adresse durchzuführen, normalerweise hätte jede Partei seine eigene Adresse.</p>
      <button id="resetContract" onClick={resetContract}>
          <span>Reset Contract</span>
      </button>
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletName.length > 0 ? (
          "Connected: " + walletName
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
      <h2 style={{ paddingTop: "0px" }}>Vertragsdaten wie sie auf der Blockchain stehen:</h2>
      <p>Starthafen: {startHarbor}</p>
      <p>Endhafen: {endHarbor}</p>
      <h2>Vertragsdaten mit AES entschlüsselt:</h2>
      <p>Starthafen: {startHarborDecrypted}</p>
      <p>Endhafen: {endHarborDecrypted}</p>
      <h2>Aktueller Status des Vertrages:</h2>
      <p>{message}</p>
      <div className="progress">
        <ul class="progress-bar">
          <li><span>Eröffnung</span></li>
          {status == 1 ? (<li class="stop"><span>Anzahlung</span></li>) : (<li><span>Anzahlung</span></li>)}
          {status == 2 ? (<li class="stop"><span>Akkreditiv überprüft und Hotscan</span></li>) : (<li><span>Akkreditiv überprüft und Hotscan.</span></li>)}
          {status == 4 ? (<li class="stop"><span>Akkreditiv überprüft und Hotscan</span></li>) : (<li><span>Akkreditiv überprüft und Hotscan</span></li>)}
          {status == 5 ? (<li class="stop"><span>Produktion abgeschlossen</span></li>) : (<li><span>Produktion abgeschlossen</span></li>)}
          {status == 6 ? (<li class="stop"><span>Stauraum gebucht</span></li>) : (<li><span>Stauraum gebucht</span></li>)}
          {status == 7 ? (<li class="stop"><span>Transport zum Hafen</span></li>) : (<li><span>Transport zum Hafen</span></li>)}
          {status == 8 ? (<li class="stop"><span>Zollprüfung abgeschlossen</span></li>) : (<li><span>Zollprüfung abgeschlossen</span></li>)}
          {status == 9 ? (<li class="stop"><span>BoL ausgestellt</span></li>) : (<li><span>BoL ausgestellt</span></li>)}
          {status == 10 ? (<li class="stop"><span>Akkreditiv Dokumente überprüft</span></li>) : (<li><span>BoL überprüft</span></li>)}
          {status == 11 ? (<li class="stop"><span>Payment</span></li>) : (<li><span>Payment</span></li>)}
          {status == 12 ? (<li class="stop"><span>Akreditiv Dokumente überprüft und freigeschaltet</span></li>) : (<li><span>Akreditiv Dokumente überprüft und freigeschalet</span></li>)}
          {status == 14 ? (<li class="stop"><span>Smart Contract ist freigeschaltet und kann zur Abholung vorgelegt werden</span></li>) : (<li><span>Smart Contract ist freigeschaltet und kann zur Abholung vorgelegt werden</span></li>)}
          {status == 15 ? (<li><span></span></li>) : (<li><span></span></li>)}
        </ul>
      </div>
      <h2>{form.length > 0 ? (<span>Deine Interaktion</span>) : (<span>Bitte authentifiziere dich um mit dem Vertrag zu interagieren</span>)}</h2>
      {button > 0 ? (
      <button id="anzahlung" onClick={functionArray[buttonFunction]}> {form}</button>
      ) : (
        <p>{form}</p>
      )
      }
    

    </div>
  );
};

export default HelloWorld;
